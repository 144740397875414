import React, { useState, useCallback } from "react"
import { Link } from "gatsby"
import Gallery from "react-photo-gallery"
import Carousel, { Modal, ModalGateway } from "react-images"

import Layout from "../../components/layout"
import FooterCaption from "../../components/footer-caption"

import One from "../../../content/assets/tools-for-blacksmiths/1.jpg"
import Two from "../../../content/assets/tools-for-blacksmiths/2.jpg"
import Three from "../../../content/assets/tools-for-blacksmiths/3.jpg"
import Four from "../../../content/assets/tools-for-blacksmiths/4.jpg"
import Five from "../../../content/assets/tools-for-blacksmiths/5.jpg"
import Six from "../../../content/assets/tools-for-blacksmiths/6.jpg"
import Seven from "../../../content/assets/tools-for-blacksmiths/7.jpg"

const ToolsForBlacksmiths = () => {
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const photos = [
    {
      src: One,
      width: 2,
      height: 1,
      caption: `Peter Ross style hammer made for William H. Baasch, Blacksmith, Grand Island, Nebraska. Made from S1 Tool Steel.`,
    },
    {
      src: Two,
      width: 1.9,
      height: 1,
      caption: `(Top) Uri Hoffi style hammer made for Lon Ninemeier, Blacksmith @ Harold Warp's Pioneer Village, Minden Nebraska. Made from 1045 steel w/Osage Orange handle. (Bottom) Uri Hoffi style 7 pound sledge for shop use. Made from 1045 steel w/26 inch handle.  Made in collaboration with  Ron Loken, Loken Forge, Omaha, NE`,
    },
    {
      src: Three,
      width: 1,
      height: 1,
      caption: `Shovel Making Jig made to use in the shop.`,
    },
    {
      src: Four,
      width: 1.2,
      height: 1,
      caption: `Chain Hook made for Don Miller, Farmer @ Grand Island, Nebraska. Made from 1040 steel.`,
    },
    {
      src: Five,
      width: 1.2,
      height: 1,
      caption: `Tongs presented to PETER ROSS, who at the time was the blacksmith at the Colonial Williamsburg Village, Williamsburg, VA. 
      
      Tongs are of frivolous Damascus and fitted with an impractical grease zerk and high temperature grease.
      Forged by myself, Dick Nietfeld, polished and fitted by Harlan (Sid) Sudemeier of Little Giant, Inc. Nebraska City, Nebraska. 
      Published in the ABANA magazine "Anvil's Ring" Spring 2000 Vol. 27 No. 4 page 26`,
    },
    {
      src: Six,
      width: 1,
      height: 1,
      caption: `Tongs presented to PETER ROSS, who at the time was the blacksmith at the Colonial Williamsburg Village, Williamsburg, VA. 
      
      Tongs are of frivolous Damascus and fitted with an impractical grease zerk and high temperature grease.
      Forged by myself, Dick Nietfeld, polished and fitted by Harlan (Sid) Sudemeier of Little Giant, Inc. Nebraska City, Nebraska. 
      Published in the ABANA magazine "Anvil's Ring" Spring 2000 Vol. 27 No. 4 page 26`,
    },
    {
      src: Seven,
      width: 3,
      height: 1,
      caption: `Pliers used in forming scrolls. 10.5" long and made of 1045 steel.`,
    },
  ]

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  return (
    <Layout>
      <article>
        <h1>Tools for Blacksmiths</h1>
        <Gallery photos={photos} onClick={openLightbox} />
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                components={{ FooterCaption }}
                currentIndex={currentImage}
                views={photos.map(x => ({
                  ...x,
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
        <Link
          style={{ display: "inline-block", marginTop: "24px" }}
          to="/gallery"
        >
          ← Back to Gallery
        </Link>
      </article>
    </Layout>
  )
}

export default ToolsForBlacksmiths
